/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

@import url("//fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");
@import "variables";
@import "mixins";

body.login {
	background: #F1F7FB;
	font-family: var(--font-family-primary);
	h1 {
		a {
			width: rem(320);
			height: rem(150);
			background: url(../img/logo.svg) no-repeat center center / contain;
		}
	}
	form {
		border: none;
	}
}

#login {
	background: none;
	box-shadow: none;
}

.login #backtoblog,
.login #nav,
.login .privacy-policy-page-link {
	a {
		color: var(--color2);
		&:hover {
			color: var(--color2);
			text-decoration: underline;
			text-decoration-color: var(--color1);
		}
	}
}

.wp-core-ui,
.language-switcher {
	input.button,
	.button-large,
	.button-primary {
		background: var(--color1);
		color: var(--color2);
		border: none;
		border-radius: rem(100);
		box-shadow: none;
		text-shadow: none;
		text-transform: none;
		font-weight: 400;
		transition: all 0.2s ease-in-out;
		&:hover,
		&:active,
		&:focus {
			background: var(--color2) !important;
			color: var(--color1);
			outline: 0;
			box-shadow: none !important;
		}
	}
}
