/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

:root {

	// COLOURS
	// ---------------

	--color1:               #F9BD05;
	--color2:               #182864;
	--color3:               #B8D6EB;
	--color4:               #EAF2F7;
	--color5:               #3B4D92;

	// TYPOGRAPHY
	// ---------------

	--text-color:           #454545;
	--text-base-size:        16px;
	--base-line-height:      1.612;
	--base-type-spacing:     1.875rem;

	--font-family-primary:   "Open Sans", Helvetica, Arial, sans-serif;
	--font-family-secondary: var(--font-family-primary);
	--font-family-headings:  var(--font-family-secondary);

	--headings-color:        var(--color2);
	--hr-color:              #C5CEDD;

}